
@use 'sass:map';
@use '~@angular/material' as mat;


  $palete: (
    50 : #e0edf9,
    100 : #b3d2ef,
    200 : #80b4e5,
    300 : #4d95da,
    400 : #00e676,
    500 : #0068ca,
    600 : #0060c5,
    700 : #0055bd,
    800 : #004bb7,
    900 : #003aab,
    A100 : #d4e0ff,
    A200 : #a1baff,
    A400 : #6e94ff,
    A700 : #5582ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
  );

  $ubutuType: mat.define-typography-config(
    $font-family: Ubuntu,
    $headline:  mat.define-typography-level(3rem, 1, 700),
  );

  @include mat.core($ubutuType);

  $primary: mat.define-palette($palete, 900);
  $accent: mat.define-palette($palete, 400);

  $app-warn: mat.define-palette(mat.$red-palette);

  $app-theme: mat.define-light-theme($primary, $accent, $app-warn);

* {
  @include mat.all-component-themes($app-theme);
  --primary: #003aab;
  --accent: rgb(0, 230, 118);
  --warning: rgb(248, 41, 41);

  --primary-rgb: 0, 58, 171;
  --accent-rgb: 0, 230, 118;
  --warning-rgb: 248, 41, 41;

  //--background: #efefef;
  --foreground: #000;
  //--background-rgb: 239, 239, 239;
  --foreground-rgb: 0, 0, 0;
}
