/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/variables.scss";

//_ Mat TABLE
table {
  width: 100%;
  th.center .mat-sort-header-container {
    justify-content: center !important;
  }

  th.right .mat-sort-header-container {
    justify-content: flex-start !important;
    flex-direction: row-reverse !important;
  }

  th.left .mat-sort-header-container {
    justify-content: flex-end !important;
    flex-direction: row-reverse !important;
  }

  td.primary {
    color: var(--ion-color-primary) !important;
  }

  td.danger {
    color: var(--ion-color-danger);
  }

  /*tr{
    th, td {
      padding: 10px !important;
      height: 40px !important;
    }
  }*/

  &.table-hover {
    tr.mat-row {
      transition: all 0.3s;
      &:hover {

        background-color: rgba(var(--ion-color-primary-rgb), 0.2);
      }
    }
  }
}


.popover-anular{
  .popover-content{
    min-width: 300px;
  }

}

ion-badge{
  font-weight: normal;
}

.input.cdk-text-field-autofilled{
  color: #000 !important;
}

ion-menu.split-pane-side {

}

ion-content {
  div.content{
    max-width: 900px;
    margin: 0 auto;
  }
}


//_ AUTOCOMPLETE
input:-webkit-autofill,

input:-webkit-autofill:hover,

input:-webkit-autofill:focus

input:-webkit-autofill,

textarea:-webkit-autofill,

textarea:-webkit-autofill:hover

textarea:-webkit-autofill:focus,

select:-webkit-autofill,

select:-webkit-autofill:hover,

select:-webkit-autofill:focus {
  border:none !important;
  -webkit-text-fill-color: #000 !important;
  transition: background-color 5000s ease-in-out 0s;
}
